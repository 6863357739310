<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1>Главная</h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p>
          Бот для развития коммуникативных навыков помогает пользователям улучшать навыки общения через интерактивные кейсы.
          Пользователь проходит сценарии, имитирующие реальные ситуации, такие как переговоры и разрешение конфликтов.
          В каждом кейсе бот дает обратную связь и объясняет, какие подходы более эффективны.
          Это делает обучение практичным и увлекательным, позволяя развивать уверенность и гибкость в общении.
        </p>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'HomeView',
};
</script>
