<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">AI BOT</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <router-link
                class="nav-link"
                :class="{ active: $route.path === '/' }"
                to="/"
              >Home</router-link>
            </li>
<!--            <li class="nav-item">-->
<!--              <router-link-->
<!--                class="nav-link"-->
<!--                :class="{ active: $route.path === '/about' }"-->
<!--                to="/about"-->
<!--              >О проекте</router-link>-->
<!--            </li>-->
            <li class="nav-item">
              <router-link
                class="nav-link"
                :class="{ active: $route.path === '/cases' }"
                to="/cases"
              >Кейсы</router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                :class="{ active: $route.path === '/settings' }"
                to="/settings"
              >Настройки</router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                :class="{ active: $route.path === '/users' }"
                to="/users"
              >Пользователи</router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                :class="{ active: $route.path === '/login' }"
                to="/login"
              >Вход</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>


  <div class="container mt-5">
    <div class="row">
      <div class="col-12">
        <router-view></router-view>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
